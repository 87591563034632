.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  color: #ffffff;
  text-transform: uppercase;
  font-size: calc(var(--size) / 2);
  font-weight: bold;
  letter-spacing: 0.1rem;
  padding: 6px;
  border-radius: 50%;
}
