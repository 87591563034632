.flight-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    flex: 1;
    overflow: auto;
  }
}
