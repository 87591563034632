.account-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 16px;

  &__body {
    flex: 1;
    overflow: auto;
    padding: 16px;
  }

  &__email-title {
    color: var(--adm-color);
  }

  &__theme-title {
    color: var(--adm-color);
    margin: auto 0;
  }

  &__exit-button {
    padding-bottom: 16px;
  }
}