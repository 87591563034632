.app {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__top {
    flex: 0;
    border-bottom: solid 1px var(--adm-color-border);
  }

  &__body {
    flex: 1;
    background-color: var(--adm-color-background);
    overflow: auto;
  }

  &__bottom {
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
  }
}

.tab-bar {
  background-color: var(--adm-color-background);
}

.layout {
  background-color: #78909b;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &__inner {
    height: 100%;
    width: 100%;
    max-width: 820px;
  }
}

.auth-layout {
  min-height: 100vh;
}
