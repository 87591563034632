.passenger-list {
  max-width: 100%;

  &__payment-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 6px;
    margin-bottom: 12px;
  }

  &__price {
    font-weight: 500;
    white-space: nowrap;
  }

  &__seat {
    white-space: nowrap;
  }

  &__comment {
    color: #666;
    font-size: 0.9em;
    width: 100%;
    line-height: 1.4;
  }

  &__comment-label {
    font-weight: 500;
  }

  &__name {
    font-weight: 500;
    overflow-wrap: anywhere;
  }

  &__status {
    white-space: nowrap;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 4px;
    color: #666;
    flex-shrink: 0;
  }
}

.passengers-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    flex: 1;
    overflow: auto;
  }
}

.collapse {
  align-items: center;
}

.station {
  color: var(--adm-color-text-secondary);
}

.tel-link {
  margin: 0;
  color: var(--adm-color-primary);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
